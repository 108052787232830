.slider {
    position: relative;
    width: 100%; /* Adjust based on card width */
    height: 200px; /* Adjust based on your desired height */
    overflow: hidden;
  }
  
  .slides {
    display: flex;
    width: calc(100% * 5); /* Assume 5 images for example */
    animation: slide 10s infinite;
  }
  
  .slides img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @keyframes slide {
    0% { transform: translateX(0); }
    20% { transform: translateX(0); }
    25% { transform: translateX(-100%); }
    45% { transform: translateX(-100%); }
    50% { transform: translateX(-200%); }
    70% { transform: translateX(-200%); }
    75% { transform: translateX(-300%); }
    95% { transform: translateX(-300%); }
    100% { transform: translateX(0); }
  }
  