.back-img {
    background-image: url("../Assets/cover.png");
    width: 100%;
    height: 80vh;
    background-size: cover;
    position: relative;
    background-position: top;
}

.back-img::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.cover-img {
    background-image: url("../Assets/Solar-1.jpg");
    width: 100%;
    height: 100vh;
    background-size: cover;
    position: relative;
}

.content-wrap {
    height: 1300px;
    width: 100%;
    background-image: url(../Assets/Home/EPC.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: #515f80;
}

.content-wrap::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 600px) {
    .cover-img::before {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
    }
}

@media only screen and (max-width: 800px) {
    .content-wrap {
        height: 1700px;
    }
}

@media only screen and (max-width: 650px) {
    .content-wrap {
        height: 2700px;
    }
}