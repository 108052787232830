.back-img2 {
    background-image: url("../../Assets/Gallery/g46.jpeg");
    width: 100%;
    height: 80vh;
    background-size: cover;
    position: relative;
}

.back-img2::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}

.tax {
    background-image: url('../../Assets/Services/Utility/img1.jpg');
    width: 100%;
    height: 100vh;
    /* position: relative; */
}

.tax::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

@media only screen and (max-width: 1024px) {
    .tax {
        height: 1200px;
        background-color: none;
        background-repeat: no-repeat;
    }
}

@media only screen and (max-width: 650px) {
    .tax {
        height: 2000px;
        background-repeat: no-repeat;
        background-position: bottom;
        background-color: #2ad0ff;
    }
}